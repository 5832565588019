// FF Meta - Light
@font-face {
  font-family: 'FFMetaCyrillic';
  src: url('#{$netstorage-fonts-path}cyrillic/MetaW05/3115f28d-46db-40b3-ba4d-1999bb176e18.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

// FF Meta - Regular
@font-face {
  font-family: 'FFMetaCyrillic';
  src: url('#{$netstorage-fonts-path}cyrillic/MetaW05/f1cc17b0-4420-4876-8b50-b0659f1c7c8c.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

// FF Meta - Medium
@font-face {
  font-family: 'FFMetaCyrillic';
  src: url('#{$netstorage-fonts-path}cyrillic/MetaW05/dc6376a6-f8f8-4f9a-9aae-29266fac5660.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

// FF Meta - Bold
@font-face {
  font-family: 'FFMetaCyrillic';
  src: url('#{$netstorage-fonts-path}cyrillic/MetaW05/b9a19748-4e27-4603-9be7-aa3d2afdeddf.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

html,
body {
  font-family: $ru-primary-font;
}
